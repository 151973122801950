/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Como el Emerald que instalé solo tenia un tema por defecto me he bajado una colección de temas de aquí:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://releases.compiz-fusion.org/0.5.2/emerald-themes-0.5.2.tar.bz2"
  }, "emerald-themes-0.5.2.tar.bz2")), "\n", React.createElement(_components.p, null, "Hay que descomprimirlo y luego desde el Gestor de temas del Emerald importamos los temas que estarán en la carpeta themes."), "\n", React.createElement(_components.p, null, "Y después de unas cuantas modificaciones del RedAlice ya tengo un poco mas bonito mi kubuntu."), "\n", React.createElement(_components.p, null, "Saludos"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Actualización:"), " Podeis encontrar una nueva colección de temas en ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2009/08/12/nuevos-temas-para-emerald/",
    title: "Nuevos temas para Emerald"
  }, "Nuevos Temas para Emerald")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
